import { AnyAction } from 'redux';
import { IUserSession } from '@entities/user-session';
import { WarehouseType } from '@enums/warehouse-type';
import { IDictionary } from '@src/core/types';

import { USER_LOADED, CURRENT_MENY_ITEM, WAREHOUSE_TYPE_INIT, SET_RESTART_SERVICE_REQUIRED, SET_FILTER } from './actions';

export interface IReduxState {
    userSession?: IUserSession;
    currentMenuItem: string;
    warehouseType: WarehouseType;
    restartServiceRequired: boolean;
    filters: IDictionary<any>;
}

export const initialState: IReduxState = {
    userSession: undefined,
    currentMenuItem: 'import',
    warehouseType: WarehouseType.Transit,
    restartServiceRequired: false,
    filters: [],
};

export const userReducer = (state: any, action: AnyAction) => {
    switch (action.type) {
        case USER_LOADED:
            return {
                ...state,
                userSession: action.payload,
            };

        case WAREHOUSE_TYPE_INIT:
            return {
                ...state,
                warehouseType: action.payload,
            };

        case CURRENT_MENY_ITEM:
            return {
                ...state,
                currentMenuItem: action.payload,
            };

        case SET_RESTART_SERVICE_REQUIRED:
            return {
                ...state,
                restartServiceRequired: action.payload,
            };

        case SET_FILTER:
            return {
                ...state,
                filters: { ...state.filters, [action.context]: action.payload },
            };
        default:
            return state;
    }
};
