import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Modal, Select, Table, Col, Row, Input, Form } from 'antd';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';

import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';

import { exception, noAccessError } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IBox } from '@entities/box';
import { IBoxGroup } from '@entities/box-group';
import { IMarking } from '@entities/marking';
import { IUserSession } from '@entities/user-session';

import { Permission, hasPermission } from '@enums/permission';

const Remaining = () => {
    const { TextArea } = Input;

    const { markingId, countryId, truckId } = useParams();
    const [api, contextHolder] = notification.useNotification();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const d = useAppDispatch();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [boxGroup, setBoxGroup] = useState<IBoxGroup>();
    const [boxes, setBoxes] = useState<Array<IBox>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        loadBoxes();
    }, [refreshRequired]);

    const loadBoxes = async () => {
        setLoading(true);

        serverFetch(`remainings/boxes`, {
            method: 'GET',
            queryParams: { markingId: markingId, countryId: countryId, truckIds: [truckId] },
        })
            .then((data) => {
                setBoxes(data);

                let item = data[0] as IBoxGroup;
                if (item) {
                    setBoxGroup(item);
                }

                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения деталей груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IBox> = [
            {
                title: 'Номер',
                dataIndex: 'number',
                width: 200,
            },
            {
                title: 'Страна',
                render: () => {
                    return boxGroup?.countryName;
                },
                width: 180,
            },
            { title: ' ' },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={boxes}
                pagination={false}
                scroll={{ y: `calc(100vh - 150px)` }}
            />
        );
    };

    return (
        <>
            <Row>
                <Col span={6}>
                    <Row>
                        <FormHeader title={`Партия`} />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                <Form.Item label='ID'>
                                    <Input disabled value={boxGroup?.consigneeCode} />
                                </Form.Item>
                                <Form.Item label='Маркировка'>
                                    <Input disabled value={boxGroup?.markingCode} />
                                </Form.Item>
                                <Form.Item label='Комментарий'>
                                    <TextArea disabled rows={4} value={boxGroup?.comment} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col offset={1} span={12}>
                    <div style={{ marginTop: 10 }}>
                        {renderToolbar()}
                        {renderTable()}
                    </div>
                </Col>
            </Row>

            {contextHolder}
        </>
    );
};

export default Remaining;
